import React from 'react'
import imgSobre from "../Assets/Predio.jpg";
function Sobre () {
  return (
    <div id='about'>
        <div className='about-image'>
            <img src={imgSobre} alt='' />
        </div>
        <div className='about-text'>
            <h1>QUEM SOMOS</h1>
            <p>A Decisão Assessoria é uma empresa focada em facilitar a formação universitária de pessoas de todo o Brasil.
Você opta por iniciar uma carreira de graduação ou pós-graduação na Argentina? Ja pensou nisso?
Seu sonho de estudar no exterior pode ser realidade! A Decisão Assessoria te ajuda nessa conquista.
Possuímos um assessoramento personalizado na busca da opção mais coerente e sensata em cursos e moradias.
Realizando todos os trâmites necessários para que esse sonho seja possível. Conta com uma equipe preparada para auxiliar desde o planejamento até sua chegada, sanando dúvidas, dando dicas e estando presente em todas as incertezas que sempre surgem em uma decisão tão única como essa: Estudar Fora.
</p>
            
        </div>
    </div>
  )
}

export default Sobre
