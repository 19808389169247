import React from "react";

import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";

import { FaTwitter } from "react-icons/fa";


const Footer = () => {
  return (
    <div id="foot">
    <div className="footer-wrapper">
      
      <div className="footer-section-one">
      <div className="footer-section-columns">
          
          <span><p className="contatss">Decisão Asessoria <br />©Todos os direitos reservados</p> 
          
          <p className="contatss2">CNPJ: 24.852.975/0001-06</p> 
          <p className="contatss2">São Borja/RS  - CEP 97670-000</p> 
          <a href="tel:983141358"><p className="contatss2">(55) 9 9625-8722</p></a>
          </span>
          
          
        </div>
        </div>
        <div className="footer-section-center">
          <div className="footer-section-columns">
          
          <div className="formDiv flex"> 
        <div className="headerDiv">
        <a href="/landpage">
          
          </a>
          
        </div> 
        <div className='formBoxLette'>
       
</div>
      </div>
          
            
           
          </div>
        </div>
        <div className="footer-section-two">
        <div className="footer-section-columns">
          
                   
        
          <div className="footer-icons-two" id="footer-icons">
        <span>
         <a id="footInsta" href="https://www.facebook.com/decisaoassessoria" target="_blank" rel="noreferrer"><FaFacebookF /></a>
         <a id="footInsta" href="https://www.instagram.com/decisaoassessoriaa" target="_blank" rel="noreferrer"><FaInstagram /></a>
         
         
         </span>
         
         {/*<a href="mailto:contato@contato.com.br" target="_blank" rel="noreferrer"><HiOutlineMail /></a>*/}
         </div>
          
        </div>
        
        
      </div>
    
    </div>
    </div>
  );
};

export default Footer;