/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import logo from "../Assets/decisao.png";
import logo2 from "../Assets/decisao.png";
import { Link } from "react-scroll";
import { FaWhatsapp } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

function Navbar ({ setPage }) {
  const [nav, setnav] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 50) {
        setnav(true);
    }
    else{
        setnav(false);
    }
  }
  window.addEventListener('scroll',changeBackground);

  const closeMenu = () => {
    document.getElementById('menu-btn').checked = false;
  };
  
  
  return (
    <nav className={nav ? "nav active" :"nav"}>
        <Link to='main' className='logo' smooth={true} duration={1000}>
        <img src={nav ? logo : logo2} alt="Logo" />
        </Link>
        <input className='menu-btn' type="checkbox" id='menu-btn'/>
        <label className="menu-icon" for="menu-btn">
            <span className="nav-icon"></span>
        </label>     
        <ul className="menu header">
            <li><Link to='main' onClick={() => {setPage("home"); closeMenu();}}  smooth={true} duration={1000}  >Home</Link></li>
            <li><Link to='about' onClick={() => {setPage("home"); closeMenu();}}  smooth={true} duration={1000} >Quem Somos</Link></li>
            
            


            
            <li><Link to='presentaion' onClick={() => {setPage("home"); closeMenu();}}  smooth={true} duration={1000} >Depoimentos</Link></li>
                       
        </ul>
        <div className="footer-icons" id="footer-icons ConvertZap">
        
         <a href="https://www.instagram.com/decisaoassessoriaa/" target="_blank" rel="noreferrer"><FaInstagram /></a>
         <a id="NavZap"  href="https://wa.me/5555996258722" target="_blank" rel="noreferrer"><FaWhatsapp data-gtm="whatsapp-click" /></a>
         </div>
    </nav>
  );
};

export default Navbar;