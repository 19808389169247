import "./App.css";
import React, { useState } from "react";
import Contact  from "./Components/Contact";
import Home from "./Components/Home";
import Navbar from "./Components/Navbar";
import Sobre from "./Components/Sobre";
import Footer from "./Components/Footer";
import Branco from "./Components/branco"; 
import Brancobranco from "./Components/brancobranco";
import BrancoTopo from "./Components/brancoTopo";
import Contratos from "./Components/Contratos";
import Consumidor from "./Components/Consumidor";
import Familia from "./Components/Familia";
import Propiedade from "./Components/Propiedade";
import Bancario from "./Components/Bancario";
import Empresarial from "./Components/Empresarial";
import Criminal from "./Components/Criminal";
import Extrajudicial from "./Components/Extrajudicial";
import GeralObrigado from "./Util/GeralObrigado";
import Previdenciario from "./Components/Previdenciario";



function App() {
  const [page, setPage] = useState("home");
  const [isTransitioning, setIsTransitioning] = useState(false);

  const handlePageChange = (newPage) => {
    setIsTransitioning(true);
    setTimeout(() => {
      setPage(newPage);
      setIsTransitioning(false);
    }, 500); // Duração da animação de 0.5 segundos
  };

  const renderPage = () => {
    switch (page) {
      case "home":
        return (
          <>
            <Home />
            <Sobre />
            <Branco />

          </>
        );
      case "Contratos":
        return (
        <>
        <BrancoTopo />
        <Contratos />
        <Brancobranco />
        </>
        );
        case "Consumidor":
        return (
        <>
        <BrancoTopo />
        <Consumidor />
        <Brancobranco />
        </>
        );
        case "Familia":
        return (
        <>
        <BrancoTopo />
        <Familia />
        <Brancobranco />
        </>
        );
        case "Propiedade":
        return (
        <>
        <BrancoTopo />
        <Propiedade />
        <Brancobranco />
        </>
        );
        case "Bancario":
        return (
        <>
        <BrancoTopo />
        <Bancario />
        <Brancobranco />
        </>
        );
        case "Empresarial":
        return (
        <>
        <BrancoTopo />
        <Empresarial />
        <Brancobranco />
        </>
        );
        case "Criminal":
        return (
        <>
        <BrancoTopo />
        <Criminal />
        <Brancobranco />
        </>
        );
        case "Extrajudicial":
        return (
        <>
        <BrancoTopo />
        <Extrajudicial />
        <Brancobranco />
        </>
        );
        case "Previdenciario":
        return (
        <>
        <BrancoTopo />
        <Previdenciario />
        <Brancobranco />
        </>
        );
        case "GeralObrigado":
        return (
        <>
        <BrancoTopo />
        <GeralObrigado />
        <Brancobranco />
        </>
        );
      default:
        return null;
    }
  };

  return (
    
    <div className="App">
      <Navbar setPage={handlePageChange} />
      <div className={`page-content ${isTransitioning ? 'slide-out' : 'slide-in'}`}>
        {renderPage()}
      </div>
      <Footer />
    </div>
  );
}

export default App;